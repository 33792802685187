
  import { defineComponent, inject, reactive, Ref, ref, watch, watchEffect } from 'vue';
import {
  createQryDailyWorkParams,
  createQryMyDailyWorkParams,
  qryDailyWork,
  QryDailyWorkParams,
  qryMyDailyWork
} from '@/api/entity/daily';
import { DailyHomeWork, createDailyHomeWork, Page, PageResponse } from 'momai'
import { timeFormat } from '@/utils/util';
import { FetchFunction, useLoadList } from '@/utils/useLoadList';
import { formatDate2 } from '@/utils/format';

const initProps = (records: any) => {
  const result = records.map( (item: any) => {
    const dailyHomeWorkItem = reactive<DailyHomeWork>(createDailyHomeWork())
    // 封装头部信息
    dailyHomeWorkItem.userAvatar = item.userAvatar
    dailyHomeWorkItem.name = item.userName
    dailyHomeWorkItem.userId = item.userId
    dailyHomeWorkItem.position = item.createTime ? timeFormat(item.createTime) : ''

    // 封装作业内容
    dailyHomeWorkItem.detailImg = item.detailImg.split(',')
    dailyHomeWorkItem.video = item.video
    dailyHomeWorkItem.videoCover = item.videoCover
    dailyHomeWorkItem.voice = item.voice || ''
    dailyHomeWorkItem.detailDesc = item.detailDesc

    // 封装点赞数、评论数、分享数
    dailyHomeWorkItem.id = item.id
    dailyHomeWorkItem.dailyId = item.dailyId
    dailyHomeWorkItem.hasDz = item.workDzColor === '1'
    dailyHomeWorkItem.dzCnt = item.workDzCnt
    dailyHomeWorkItem.hasSc = item.workScColor === '1'
    dailyHomeWorkItem.scCnt = item.workScCnt
    dailyHomeWorkItem.fxCnt = item.workShareCnt
    dailyHomeWorkItem.pjCnt = item.workPjCnt
    dailyHomeWorkItem.createTime = item.createTime ? timeFormat(item.createTime) : ''
    return dailyHomeWorkItem
  })
  return result
}

const totalNum = ref(-1)
const totalNum4Mine = ref(-1)
const useLoadListEffect = (qryParam: QryDailyWorkParams) => {
  const dailyHomeWorks = ref<DailyHomeWork[]>([])
  const { dailyTime, pageInfo } = qryParam
  const fetch: FetchFunction = (pageData, loadEnd) => {
    qryDailyWork( { dailyTime, pageInfo  } )
      .then(( { records, total }: PageResponse<DailyHomeWork>) => {
        const result = initProps(records)
        totalNum.value = total
        dailyHomeWorks.value.push(...result)
        loadEnd()
      })
  }
  const { loadMore, finished, loading } = useLoadList({
    fetch,
    total: totalNum,
    list: dailyHomeWorks,
    pageData: pageInfo
  })
  return { loadMore, finished, loading, dailyHomeWorks }
}


const useLoadListEffect4Mine = (qryParam: QryDailyWorkParams) => {
  const dailyHomeWorks4Mine = ref<DailyHomeWork[]>([])
  const { pageInfo } = qryParam
  const fetch: FetchFunction = (pageData, loadEnd) => {
    qryMyDailyWork( { pageInfo })
      .then(( { records, total }: PageResponse<DailyHomeWork>) => {
        const result = initProps(records)
        totalNum4Mine.value = total
        dailyHomeWorks4Mine.value.push(...result)
        loadEnd()
      })
  }
  const { loadMore, finished, loading } = useLoadList({
    fetch,
    total: totalNum4Mine,
    list: dailyHomeWorks4Mine,
    pageData: pageInfo
  })
  return { loadMore, finished, loading, dailyHomeWorks4Mine }
}


export default defineComponent({
  name: 'WorkList',
  props: {
    dailyTime: {
      type: String,
    }
  },
  setup(props) {
    let dailyHomeWorks = ref<DailyHomeWork[]>([])
    let dailyHomeWorks4Mine = ref<DailyHomeWork[]>([])
    let loadMore4DailyWork = ()=> {}
    let finished4DailyWork = ref(false);
    let loading4DailyWork = ref(false);
    let loadMore4MyDailyWork = ()=> {}
    let finished4MyDailyWork = ref(false);
    let loading4MyDailyWork = ref(false);
    const dailyTime = ref(props.dailyTime)

    const handleWork = (activeIndex: number | string) => {
      console.log(111111)
      if (activeIndex === 0) {
        const qryParams = createQryDailyWorkParams()
        qryParams.dailyTime = dailyTime.value? dailyTime.value: formatDate2(new Date())
        const resp = useLoadListEffect(qryParams)
        loadMore4DailyWork = resp.loadMore
        finished4DailyWork.value = resp.finished.value
        loading4DailyWork.value = resp.loading.value
        dailyHomeWorks.value = resp.dailyHomeWorks.value
      } else if (activeIndex === 1) {
        const qryParams = createQryMyDailyWorkParams()
        const resp = useLoadListEffect4Mine(qryParams)
        loadMore4MyDailyWork = resp.loadMore
        finished4MyDailyWork.value = resp.finished.value
        loading4MyDailyWork.value = resp.loading.value
        dailyHomeWorks4Mine.value = resp.dailyHomeWorks4Mine.value
      }
    }
    handleWork(0)

    const changeDateVal = inject<Ref>('changeDateVal')!
    watch(changeDateVal, ()=> {
      dailyTime.value = changeDateVal.value
      handleWork(0)
    })
    const active = inject<Ref>('tabactive')!
    watch(active, ()=> {
      handleWork(active.value)
    })
    return {
      active,
      handleWork,
      dailyHomeWorks,
      dailyHomeWorks4Mine,
      loadMore4DailyWork,
      finished4DailyWork,
      loading4DailyWork,
      loadMore4MyDailyWork,
      finished4MyDailyWork,
      loading4MyDailyWork,
      immediateCheck: false
    }
  }
})
