
import { defineComponent, provide, ref, Ref } from 'vue';
import { qryUserDailyWork4Month } from '@/api/entity/daily';
import { formatDate, formatDate4Month } from '@/utils/format';

const useQryCalenderEffect = (month: Ref<string>) => {
  const signArr = ref<number[]>([])
  qryUserDailyWork4Month(month.value).then( (res: any) => {
    const signs = res.map( (item: string) => {
      const sign = new Date(formatDate(item)).getDate()
      return sign
    })
    signArr.value.push(...signs)
  })
  return { signArr }
}
let signs = ref<any>([])

export default defineComponent ({
  name: 'Calendar',
  setup(props) {
    const downFlag = ref(false)
    const month = ref(formatDate4Month(new Date()))
    let result = useQryCalenderEffect(month);
    signs = result.signArr
    provide('arrDate', signs)
    // 提供方法让子组件修改值
    provide('changeMonth', (value: string) => {
      month.value = formatDate4Month(new Date(value))
      result = useQryCalenderEffect(month);
      signs.value = []
      signs.value = result.signArr.value
    })

    /**
     * 展开日历 or 收起日历
     */
    const showLongOrShort = ( flag: string) => {
      downFlag.value = flag === 'open'
    }

    return {
      signs,
      downFlag,
      showLongOrShort,
    }
  }
})
