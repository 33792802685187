
import Calendar from '@/views/daily/components/Calendar.vue'
import WorkList from '@/views/daily/components/WorkList.vue'
import { defineComponent, provide, reactive, ref, watch, watchEffect } from 'vue';
import { qryDailyInfo, qryTotalWork } from '@/api/entity/daily'
import { formatDate4CH, formatDate2 } from '@/utils/format'
import MomaiCommentPublish from '@/components/Momai/MomaiCommentPublish/Index.vue';
import { api } from '@/api/useAxios';
import { Dialog, Toast } from 'vant';
import { getUserId } from '@/utils/auth';
import { changeShareInfo } from '@/utils/wechat'

interface DailyInfo {
  dailyId: number;
  title: string;
  detailDesc: string;
  detailImg: string;
  video: string;
  videoCover: string;
  voice: string;
  dailyTime: string;
  joinNum: string; // 参与人数
  totalNum: string; // 总用户
  finshReward: number;
}

const createDailyInfoProps = (): DailyInfo => ({
  dailyId: 0,
  title: '',
  detailDesc: '',
  detailImg: '',
  video: '',
  videoCover: '',
  voice: '',
  dailyTime: '',
  joinNum: '',
  totalNum: '',
  finshReward: 0
})

const useQryWork4DailyEffect = (dailyTime: string) => {
  const dailyInfo = reactive<DailyInfo>(createDailyInfoProps())
   qryDailyInfo(dailyTime).then( (res: any) => {
      dailyInfo.dailyId = res?.id
      dailyInfo.title = res?.title || ''
      dailyInfo.detailDesc = res?.detailDesc? res.detailDesc: '未获取到当日打卡主题～'
      dailyInfo.video = res?.video || ''
      dailyInfo.videoCover = res?.videoCover || ''
      dailyInfo.detailImg = res?.detailImg || ''
      dailyInfo.voice = res?.voice || ''
      dailyInfo.dailyTime = formatDate2(res?.dailyTime) || formatDate2(new Date())
      dailyInfo.joinNum = res?.joinNum || '0'
      dailyInfo.totalNum = res?.totalNum || '0'
      dailyInfo.finshReward = res?.finshReward
      changeShareInfo({
        title: dailyInfo.title ? dailyInfo.title : process.env.VUE_APP_PAGE_TITLE,
        desc: dailyInfo.detailDesc ? dailyInfo.detailDesc : process.env.VUE_APP_PAGE_DESC,
        link: window.location.href,
        imgUrl: process.env.VUE_APP_SHARE_IMG
      })
  })
  return  { dailyInfo }
}

// 查询累计打卡天数
const useQryTotalWorkEffect = () => {
  const totalWork = ref(0)
  qryTotalWork().then(res => {
    totalWork.value = Number(res)
  })
  return totalWork
}

export default defineComponent({
  name: 'Daily',
  components: {MomaiCommentPublish, Calendar, WorkList },
  setup() {
   const commentTag = ref(false)
   let daily = reactive({
     dailyInfo: {
       dailyId: -1
     }
   })
   const dailyTime = ref<string>(formatDate2(new Date()));

   const { dailyInfo } = useQryWork4DailyEffect(dailyTime.value);
   daily.dailyInfo = dailyInfo
   const totalWork = useQryTotalWorkEffect();

   provide('commentTag', commentTag)
   const handleDaily = () => {
     if (!daily.dailyInfo.dailyId) {
       Toast.fail("抱歉，未查询到今日打卡主题")
       return
     }
     commentTag.value = true
   }

    // 每日提交
    const tabactive = ref(0)
    const dailySub = (params: any) => {
      Dialog.confirm({
        title: '提示',
        message: '等待导师审核，一旦审核通过将会获得'+ dailyInfo?.finshReward +'魔豆积分！'
      }).then(() => {
        const saveParams = {
          "dailyId": daily.dailyInfo.dailyId,
          "detailDesc": params.info,
          "detailImg": params.pImg,
          "userId": getUserId(),
          "video": params.pVideo,
          "videoCover": params.pVideoCover,
          "voice": params.pVoice,
        }
        api.post('/mmdailywork/save', saveParams).then( res => {
          Toast.success("提交成功")
          commentTag.value = false
          tabactive.value = 1
        })
      })

    }
    provide('tabactive', tabactive)


    const changeDateVal = ref('')
    const changeDate = (date: any) => {
      const { dailyInfo } = useQryWork4DailyEffect(date.date4day)
      daily.dailyInfo = dailyInfo
      dailyTime.value = date.date4day
      changeDateVal.value = date.date4day
    }
    provide('changeDate', changeDate)
    provide('changeDateVal', changeDateVal)

  
    return {
      daily,
      totalWork,
      handleDaily,
      commentTag,
      dailySub,
      formatDate4CH,
      dailyTime
    }
  }
})
